.form > * {
  display: block;
  margin: 20px;
}

.fieldset {
  border: none;
  padding: 0;
}

.backLink {
  margin-bottom: 20px;
  a:focus {
    border-bottom: 3px solid #0b0c0c;
    margin-bottom: 13px;
    outline: none;
    text-decoration: none;
  }
}

.conditionalInput {
  position: relative;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 38px;
  width: 33.33%;
  max-width: 39.375rem;

  label {
    margin-right: 0;
    span {
      width: max-content;
    }
  }
}

.conditionalInput::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #b1b4b6;
}

.submitButtonGroup {
  display: flex;
  padding-top: 30px;
}

.spinner {
  margin-left: 10px;
  margin-top: 5px;
}
