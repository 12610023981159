$govuk-colour-black: #0b0c0c;

.footer {
  width: 100%;
  a:focus {
    color: $govuk-colour-black;
    border-bottom: 3px solid $govuk-colour-black;
    margin-bottom: -3px;
    outline: none;
    text-decoration: none;
  }
}
