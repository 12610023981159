.Container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0.2rem 0rem;
  overflow-y: hidden;
}
.formContainer {
  flex: 1;
  text-align: left;
  display: flex;
  height: 85vh;
  flex-direction: column;
  padding: 0rem 0.2rem 0rem 0rem;
}

.backlink {
  text-align: left;
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
}

.textField {
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
  text-align: left;
  display: block;
  box-sizing: border-box;
  textarea {
    resize: none;
    height: 15rem;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }
}

.fieldset {
  border: none;
  padding: 0.2rem 0.9375rem 0.2rem 0.9375rem;
}

.conditionalInput {
  position: relative;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 38px;
  max-width: 39.375rem;

  label {
    margin-right: 15px;
    span {
      width: max-content;
    }
  }
}

.conditionalInput::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #b1b4b6;
}

.divider {
  width: 100%;
  border: 0;
  margin-bottom: 90px;
  height: 2px;
}

/* For screens with a min height of 990px */
@media (max-height: 990px) {
  .divider {
    height: 0px; /* Adjusted height for taller screens */
    margin-bottom: 0rem;
  }
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0rem 0rem 0rem 0.9375rem;
  margin-top: -10px;
  margin-bottom: 15px;
}

.pagesContainer {
  align-items: left;
  width: 8rem;
  padding: 0rem 0.9375rem 0rem 0rem;
}

.submitButton {
  padding: 0rem 0rem 0rem 0.5rem;
  width: 9rem;
}
