.container {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(min-content, max-content) 2fr;
}

p {
  font-size: 19px;
  font-weight: 400;
}

.backLink {
  margin-bottom: -10px;
  a:focus {
    border-bottom: 3px solid #0b0c0c;
    margin-bottom: 13px;
    outline: none;
    text-decoration: none;
  }
}
